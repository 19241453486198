/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-lines */
const MainTheme = {
  colors: {
    primary: {
      50: "#FFF5E5",
      100: "#FCEBDB",
      200: "#F9E1D1",
      300: "#F6D7C7",
      400: "#CDA77B",
      500: "#B99068",
      600: "#A57956",
      700: "#916243",
      800: "#7D4B30",
      900: "#69341D",
    },
    secondary: {
      50: "#E6F5FF",
      100: "#CCEBFF",
      200: "#99D8FF",
      300: "#66C3FF",
      400: "#33B2FF",
      500: "#009DFF",
      600: "#007ECC",
      700: "#005E99",
      800: "#003F66",
      900: "#001F33",
    },
    third: {
      50: "#e0e2f1",
      100: "#ccd3e4",
      200: "#b8c3d7",
      300: "#a3b4ca",
      400: "#8fa5bd",
      500: "#7a96b0",
      600: "#607783",
      700: "#46586f",
      800: "#2c395d",
      900: "#24273f",
    },
    background: {
      50: "#F0F3F5",
      100: "#E0E7EB",
      200: "#C2CED6",
      300: "#A3B6C2",
      400: "#859EAD",
      500: "#668599",
      600: "#526B7A",
      700: "#3D505C",
      800: "#29353D",
      900: "#141B1F",
    },
    success: {
      50: "#E9FBF0",
      100: "#D4F7E1",
      200: "#A8F0C3",
      300: "#7DE8A4",
      400: "#51E186",
      500: "#26D968",
      600: "#1EAE53",
      700: "#17823E",
      800: "#0F572A",
      900: "#082B15",
    },
    error: {
      50: "#FDE8E8",
      100: "#FBD0D0",
      200: "#F7A1A1",
      300: "#F37272",
      400: "#EF4444",
      500: "#EB1414",
      600: "#BC1010",
      700: "#8D0C0C",
      800: "#5E0808",
      900: "#2F0404",
    },
    warning: {
      50: "#FEF0E6",
      100: "#FEE1CD",
      200: "#FCC39C",
      300: "#FBA56A",
      400: "#FA8838",
      500: "#F96A06",
      600: "#C75505",
      700: "#953F04",
      800: "#632A03",
      900: "#321501",
    },
    info: {
      50: "#FFF4D9",
      100: "#FFEDBF",
      200: "#FFD86D",
      300: "#FFD86D",
      400: "#FFCB3D",
      500: "#F2BC29",
      600: "#DCA40B",
      700: "#B88907",
      800: "#9B7511",
      900: "#674E08",
    },
    common: {
      50: "#FFF",
      900: "#000",
    },
    alpha: {
      8: "rgba(61, 80, 92, 0.08)",
      12: "rgba(61, 80, 92, 0.12)",
      16: "rgba(61, 80, 92, 0.16)",
    },
  },
  // components: {
  //   Box: {
  //     baseStyle: {
  //       rounded: 12,
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //     },
  //     variants: {
  //       achievementListItem: {
  //         bg: "common.50",
  //         overflow: "hidden",
  //         width: "full",
  //         rounded: 20,
  //         borderWidth: 1,
  //         borderColor: "background.100",
  //         p: "2",
  //       },
  //       achievementModal: {
  //         bg: "common.50",
  //         overflow: "hidden",
  //         width: "80%",
  //         rounded: 40,
  //         borderWidth: 1,
  //         borderColor: "background.100",
  //         p: "2",
  //       },
  //       split: {
  //         display: "flex",
  //         flexDirection: "row",
  //         justifyContent: "space-between",
  //         alignItems: "center",
  //       },
  //       listItem: {
  //         shadowOpacity: 0.2,
  //         shadowColor: "background.50",
  //         shadowOffset: { width: -1, height: 1 },
  //         mx: 2,
  //         my: 1,
  //         shadowRadius: 2,
  //         borderColor: "background.50",
  //       },
  //       soundSetting: {
  //         p: "2",
  //         rounded: 20,
  //         backgroundColor: "background.50",
  //       },
  //       voiceSpeed: {
  //         w: "full",
  //         p: "2",
  //         rounded: 20,
  //         backgroundColor: "background.50",
  //       },
  //       new: {
  //         bg: "primary.500",
  //         rounded: 3,
  //         p: [2, 2],
  //         _text: {
  //           fontSize: "sm",
  //           fontWeight: "bold",
  //         },
  //       },
  //       sortingGameDefault: {
  //         w: "full",
  //         minH: 65,
  //         borderWidth: 2,
  //         borderColor: "background.100",
  //         d: "flex",
  //         flexWrap: "wrap",
  //       },
  //       wordList: {
  //         p: 2,
  //         mr: 2,
  //         rounded: 4,
  //         mt: 2,
  //         backgroundColor: "primary.100",
  //       },
  //       StatisticsCardDefault: {
  //         minW: "33%",
  //         h: 186,
  //         borderWidth: 1,
  //       },
  //       circleLangIcon: {
  //         borderRadius: 50,
  //         borderWidth: 1,
  //         borderColor: "background.100",
  //         objectFit: "cover",
  //         resizeMode: "contain",
  //         width: 55,
  //         height: 55,
  //         overflow: "hidden",
  //         zIndex: 2,
  //       },
  //       inputContainer: {
  //         borderWidth: 1,
  //         borderColor: "background.100",
  //         rounded: 16,
  //         minH: 50,
  //         my: 5,
  //       },
  //       feedbackModal: {
  //         rounded: 18,
  //         width: "full",
  //         position: "absolute",
  //         bottom: "1%",
  //         shadowColor: "background.300",
  //         shadowOffset: {
  //           width: 0,
  //           height: 2,
  //         },
  //         shadowOpacity: 0.25,
  //         shadowRadius: 4,
  //         elevation: 5,
  //       },
  //       circle: {
  //         borderRadius: 50,
  //         borderWidth: 1,
  //         borderColor: "background.100",
  //       },
  //       statisticsTextBox: {
  //         p: 3,
  //         paddingTop: 0,
  //         zIndex: 2,
  //         w: "100%",
  //         alignItems: "flex-start",
  //       },
  //       statisticsBox: {
  //         bg: "common.50",
  //         w: "100%",
  //       },
  //       statisticsSubtextBox: {
  //         w: "100%",
  //         pb: 3,
  //         alignItems: "flex-start",
  //       },
  //       progressChartBoxStyle: {
  //         position: "relative",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         transform: [{ rotate: "-90deg" }],
  //         bottom: -120,
  //       },
  //       progressChartInnerBoxStyle: {
  //         zIndex: 1,
  //         justifyContent: "center",
  //         w: "100%",
  //         position: "absolute",
  //         transform: [{ rotate: "90deg" }],
  //         left: 50,
  //       },
  //       dividerStyle: {
  //         w: "100%",
  //         bg: "common.50",
  //         height: "100%",
  //         position: "absolute",
  //         left: -120,
  //         zIndex: 0.5,
  //         transform: [{ rotate: "90deg" }],
  //       },
  //       calendarBoxStyle: {
  //         borderWidth: 1,
  //         borderColor: "background.100",
  //         w: "full",
  //         mb: 5,
  //         p: 2,
  //       },
  //       courseSelectorHeader: {
  //         backgroundColor: "info.100",
  //         rounded: 0,
  //         width: "full",
  //         maxHeight: "1/6",
  //         minHeight: 150,
  //         justifyContent: "flex-start",
  //         zIndex: 1,
  //         borderTopWidth: 0,
  //       },
  //       languageSelector: {
  //         w: "full",
  //         h: "full",
  //         justifyContent: "flex-start",
  //       },
  //     },
  //   },
  //   Button: {
  //     baseStyle: {
  //       _pressed: {
  //         opacity: 70,
  //       },
  //       rounded: 8,
  //       _text: {
  //         color: "common.900",
  //         fontWeight: 600,
  //         isTruncated: true,
  //       },
  //     },
  //     variants: {
  //       main: {
  //         bg: "primary.500",
  //       },
  //       secondary: {
  //         _text: {
  //           color: "primary.500",
  //         },
  //       },
  //       ghost: {
  //         bg: "background.0",
  //         borderWidth: 1,
  //         borderColor: "background.900",
  //       },
  //       disabled: {
  //         bg: "primary.100",
  //         rounded: 16,
  //       },
  //       game: {
  //         bg: "primary.500",
  //         rounded: 16,
  //       },
  //       success: {
  //         bg: "success.600",
  //         _text: {
  //           color: "common.50",
  //         },
  //         rounded: 16,
  //         w: "full",
  //       },
  //       error: {
  //         bg: "error.400",
  //         _text: {
  //           color: "common.50",
  //         },
  //         rounded: 16,
  //         w: "full",
  //       },
  //       introScreen: {
  //         bg: "background.0",
  //         borderWidth: 0,
  //       },
  //       social: {
  //         bg: "background.0",
  //         borderColor: "background.50",
  //         borderWidth: 2,
  //         my: 1,
  //       },
  //     },
  //   },
  //   Heading: {
  //     baseStyle: {
  //       _light: {
  //         color: "primary.500",
  //       },
  //       _dark: {
  //         color: "primary.50",
  //       },
  //     },
  //     variants: {},
  //   },
  //   Icon: {
  //     baseStyle: { mx: 2 },
  //     variants: {
  //       success: {
  //         color: "success.400",
  //       },
  //       error: {
  //         color: "error.400",
  //       },
  //     },
  //   },
  //   Image: {
  //     baseStyle: { objectFit: "contain", resizeMode: "contain" },
  //     variants: {
  //       matchGameIcon: {
  //         height: 60,
  //         width: 60,
  //       },
  //       vocabularyGameImage: {
  //         h: 150,
  //         w: 150,
  //       },
  //       listItem: {
  //         h: 45,
  //         w: 45,
  //       },
  //       statisticsIcon: {
  //         h: 60,
  //         w: 60,
  //       },
  //       achievementModalImage: {
  //         h: 150,
  //         w: 150,
  //       },
  //       fluencyIcon: {
  //         h: 30,
  //         w: 30,
  //       },
  //       courseItemImage: {
  //         position: "absolute",
  //         w: 100,
  //         h: 100,
  //         zIndex: 1,
  //         right: 0,
  //         alignSelf: "center",
  //         resizeMode: "contain",
  //         borderRadius: 50,
  //       },
  //       flagIcon: {
  //         w: 7,
  //         h: 7,
  //         mx: 2,
  //       },
  //     },
  //   },
  //   HStack: {
  //     baseStyle: {
  //       justifySelf: "center",
  //       alignContent: "space-between",
  //       alignItems: "center",
  //     },
  //     variants: {
  //       wrap: {
  //         display: "flex",
  //         flexWrap: "wrap",
  //       },
  //       unitListItem: {
  //         h: 82,
  //         w: "full",
  //       },
  //       cardContainer: {
  //         flexWrap: "wrap",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         pt: 4,
  //         w: 450,
  //         h: "3/4",
  //       },
  //     },
  //   },
  //   IconButton: {
  //     variants: {
  //       default: {
  //         _icon: {
  //           color: "background.900",
  //         },
  //         _pressed: {
  //           bg: "common.50",
  //           opacity: 30,
  //         },
  //       },
  //     },
  //   },
  //   Input: {
  //     defaultProps: {
  //       borderColor: "background.100",
  //       _focus: {
  //         bg: "common.50",
  //         opacity: 50,
  //         borderColor: "secondary.500",
  //       },
  //     },
  //     variants: {
  //       courseSearch: {
  //         borderColor: "common.50",
  //       },
  //     },
  //   },
  //   Pressable: {
  //     defaultProps: {
  //       _active: {
  //         opacity: 30,
  //       },
  //       _pressed: {
  //         opacity: 30,
  //       },
  //       _disabled: {
  //         opacity: 30,
  //       },
  //     },
  //     variants: {
  //       listItem: {
  //         px: 2,
  //         py: 3,
  //         flexDir: "column",
  //         _active: {
  //           opacity: 30,
  //         },
  //         _pressed: {
  //           opacity: 30,
  //         },
  //         rounded: 14,
  //         bg: "common.50",
  //         overflow: "hidden",
  //         width: "full",
  //       },
  //       achievementListItem: {
  //         _active: {
  //           opacity: 30,
  //         },
  //         _pressed: {
  //           opacity: 30,
  //         },
  //         bg: "common.50",
  //         overflow: "hidden",
  //         width: "full",
  //       },
  //       card: ({ isMatchGame }) => {
  //         return {
  //           w: "1/2",
  //           maxHeight: isMatchGame ? "2/5" : "1/2",
  //           maxW: 176,
  //           h: 180,
  //           borderWidth: 3,
  //           rounded: 15,
  //           m: 1,
  //         };
  //       },
  //       sortingGameAnswer: {
  //         h: 45,
  //         minW: 50,
  //         borderWidth: 2,
  //         borderColor: "background.100",
  //         rounded: 8,
  //         justifyContent: "center",
  //         alignItems: "center",
  //         ml: 2,
  //         mt: 2,
  //         p: 1,
  //         //TODO: remove once theme padding is added
  //       },
  //       courseItemDefault: {
  //         borderWidth: 2,
  //         borderColor: "background.100",
  //         rounded: 16,
  //         minH: 100,
  //         justifyContent: "center",
  //         alignItems: "center",
  //         mb: 2,
  //       },
  //       courseNativeLang: {
  //         bg: "common.50",
  //         borderWidth: 1,
  //         borderColor: "background.100",
  //         rounded: 16,
  //         minH: 60,
  //         justifyContent: "center",
  //         alignItems: "center",
  //         w: "full",
  //       },
  //       vocabularyImageBox: {
  //         bg: "secondary.50",
  //         w: "full",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         borderTopLeftRadius: 16,
  //         borderTopRightRadius: 16,
  //         borderWidth: 3,
  //         borderBottomWidth: 0,
  //         borderColor: "background.100",
  //         p: 37,
  //       },
  //       vocabularyBoxDefault: ({ isBottom, isSingleBox }) => {
  //         return {
  //           borderBottomLeftRadius: isBottom ? 16 : 0,
  //           borderBottomRightRadius: isBottom ? 16 : 0,
  //           rounded: isSingleBox ? 16 : 0,
  //           borderTopWidth: isBottom ? 0 : 3,
  //           borderWidth: 3,
  //           borderColor: "background.100",
  //           maxW: "full",
  //           px: 2,
  //         };
  //       },
  //       buttonContainer: {
  //         rounded: 6,
  //         borderWidth: 3,
  //         borderColor: "background.100",
  //         p: 1.5,
  //         m: 0.5,
  //       },
  //       circleIcon: {
  //         backgroundColor: "primary.100",
  //         h: 25,
  //         w: 25,
  //         borderRadius: 50,
  //         justifyContent: "center",
  //         alignItems: "center",
  //       },
  //       NativeLangItem: ({ index }) => {
  //         return {
  //           bg: "common.50",
  //           borderTopWidth: index <= 1 ? 1 : 0,
  //           borderBottomWidth: index !== 0 ? 1 : 0,
  //           borderColor: "background.100",
  //           minH: 70,
  //           justifyContent: "center",
  //           alignItems: "center",
  //           w: "full",
  //         };
  //       },
  //     },
  //   },
  //   Text: {
  //     baseStyle: {
  //       fontWeight: 500,
  //       fontFamily: "body",
  //     },
  //     variants: {
  //       highlight: {
  //         fontWeight: 600,
  //       },
  //       error: {
  //         color: "error.500",
  //       },
  //       secondary: { color: "secondary.500" },
  //       primary: { color: "common.900" },
  //       statText: { fontWeight: 600 },
  //       light: {
  //         color: "background.300",
  //       },
  //       white: { color: "common.50" },
  //       fluencyTextStyle: {
  //         fontStyle: "normal",
  //         fontSize: 20,
  //         fontWeight: "600",
  //       },
  //     },
  //   },
  //   VStack: {
  //     baseStyle: {
  //       px: 1,
  //       py: 2,
  //       justifyContent: "center",
  //       alignContent: "stretch",
  //     },
  //     variants: {
  //       courseSelector: {
  //         //TODO: remove once theme padding is added?
  //         px: 3,
  //         pt: 6,
  //         mt: 3,
  //         backgroundColor: "common.50",
  //         w: "full",
  //         borderRadius: 24,
  //         alignItems: "flex-start",
  //         justifyContent: "space-evenly",
  //       },
  //       courseSelectorHeader: {
  //         w: "full",
  //         h: "5/6",
  //         alignItems: "flex-start",
  //         justifyContent: "space-evenly",
  //         px: 3,
  //       },
  //     },
  //   },
  //   Progress: {
  //     baseStyle: {
  //       alignItems: "flex-start",
  //     },
  //   },
  //   ScrollView: {
  //     variants: {
  //       courseSelector: {
  //         backgroundColor: "common.50",
  //         w: "full",
  //       },
  //     },
  //   },
  // },
  config: {
    initialColorMode: "light",
  },
  fontConfig: {
    Poppins: {
      100: {
        normal: "Poppins",
      },
      200: {
        normal: "Poppins",
      },
      300: {
        normal: "Poppins",
      },
      400: {
        normal: "Poppins",
      },
      500: {
        normal: "Poppins",
      },
      600: {
        normal: "Poppins",
      },
      700: {
        normal: "Poppins",
      },
    },
  },
  fonts: {
    body: "Poppins",
    heading: "Poppins",
    mono: "Poppins",
  },
  space: {
    sp1: "4px",
    sp2: "8px",
    sp3: "12px",
    sp4: "16px",
    sp5: "20px",
    sp6: "24px",
  },
};

export default MainTheme;
