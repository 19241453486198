import * as React from "react";
import MainTheme from "../Constant/colors";
import dayjs from "dayjs";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {
  LINGINSTAGRAM,
  LINGOFFICIAL,
  LINGOPENCHAT,
} from "../Constant/constant";

export default function LandingPage() {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      backgroundColor: MainTheme.colors.third[900],
      py: 5,
      px: 5,
    },
    subContainer: { justifyContent: "center", alignContent: "center" },
    header: {
      display: "flex",
      minHeight: "50px",
      justifyContent: "flex-start",
      alignItems: "center",
      color: MainTheme.colors.third[800],
    },
    footer: {
      display: "flex",
      minHeight: "50px",
      justifyContent: "flex-end",
      alignItems: "center",
      color: MainTheme.colors.third[800],
    },
    logo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      flex: 1,
      resizeMode: "contain",
      maxWidth: "100px",
    },
    headingLogo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[200],
      flex: 1,
      resizeMode: "contain",
      fontWeight: 300,
    },
    subtitleLogo: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.common[50],
      flex: 1,
      resizeMode: "contain",
      fontWeight: 700,
    },
    description: {
      maxWidth: "400px",
      borderBottom: "1px solid",
      borderWidth: "1px",
      borderColor: MainTheme.colors.common[50],
    },
    description2: {
      // borderBottom: "1px solid",
      // borderWidth: "1px",
      fontWeight: 900,
      color: MainTheme.colors.primary[400],
    },
    body1: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.common[50],
      flex: 1,
      resizeMode: "contain",
    },
    body2: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.common[50],
      resizeMode: "contain",
      cursor: "pointer",
    },
    bodyImage: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      flex: 1,
      maxWidth: "250px",
      resizeMode: "contain",
    },
    thumbnail: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: MainTheme.colors.primary[300],
      // flex: 1,
      // maxWidth: "350px",
      resizeMode: "contain",
      borderRadius: "16px",
      // padding: "5px",

      width: "100%", // Ensures image fits the grid width
      height: "auto", // Maintains aspect ratio
      objectfit: "contain", // Ensures the image doesn't stretch
    },
  };

  const currentYear = dayjs().year();

  return (
    <Box sx={styles.container}>
      {/* header */}
      <Grid container spacing={2} style={styles.subContainer}>
        <Grid xs={12} md={8} style={styles.header}>
          #bn2much
        </Grid>
      </Grid>
      {/* body */}
      <Grid container spacing={2} justifyContent="center">
        {/* logo */}
        <Grid container direction="column" pb={3}>
          <Grid container justifyContent="center">
            <img
              alt="logoBn2much"
              src={require("../Constant/img/logo_bn2much.png")}
              style={styles.logo}
              loading="lazy"
            />
          </Grid>
          <Grid container justifyContent="center">
            <Typography variant="h5" gutterBottom style={styles.headingLogo}>
              bn2much
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          style={styles.description}
          pb={2}
          mb={3}
        >
          <Grid container justifyContent="center">
            <Typography variant="h5" gutterBottom style={styles.subtitleLogo}>
              รับซื้อ-ขาย-แลกเปลี่ยน
            </Typography>
          </Grid>
          <Grid container justifyContent="center">
            <Typography variant="h5" gutterBottom style={styles.subtitleLogo}>
              BRANDNAME แท้ 100%
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          style={styles.description2}
          pb={2}
          mb={3}
        >
          <Grid container justifyContent="center">
            <Typography
              variant="subtitle2"
              gutterBottom
              style={styles.description2}
            >
              BUY-SELL-TRADE AUTHENTIC BRANDNAME
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={styles.body1} mb={3}>
          <Grid container xs={12} sm={12} md={4} p={2} height={180}>
            <Grid
              container
              justifyContent="center"
              border={2}
              borderRadius={8}
              borderColor={MainTheme.colors.background[700]}
              height={"100%"}
              alignItems={"center"}
              width={"100%"}
              onClick={() => window.open(LINGOFFICIAL, "_blank")}
              style={{ cursor: "pointer" }}
            >
              <img
                alt="logoBn2much"
                src={require("../Constant/img/thumbNailLineOfficial.png")}
                style={styles.bodyImage}
                loading="lazy"
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={4} p={2} height={180}>
            <Grid
              container
              justifyContent="center"
              border={2}
              borderRadius={8}
              borderColor={MainTheme.colors.background[700]}
              height={"100%"}
              alignItems={"center"}
              width={"100%"}
              onClick={() => window.open(LINGINSTAGRAM, "_blank")}
              style={{ cursor: "pointer" }}
            >
              <img
                alt="logoBn2much"
                src={require("../Constant/img/thumbNailInstagram.png")}
                style={styles.bodyImage}
                loading="lazy"
              />
            </Grid>
          </Grid>
          <Grid container xs={12} sm={6} md={4} p={2} height={180}>
            <Grid
              container
              justifyContent="center"
              border={2}
              borderRadius={8}
              borderColor={MainTheme.colors.background[700]}
              height={"100%"}
              alignItems={"center"}
              width={"100%"}
              onClick={() => window.open(LINGOPENCHAT, "_blank")}
              style={{ cursor: "pointer" }}
            >
              <img
                alt="logoBn2much"
                src={require("../Constant/img/thumbNailLineSquare.png")}
                style={styles.bodyImage}
                loading="lazy"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={styles.body2}
          mb={3}
          flexDirection={"row"}
          // p={5}
          // spacing={2}
          // item
          xs={12}
          onClick={() => window.open(LINGOFFICIAL, "_blank")}
        >
          <Grid justifyContent="center" xs={12} sm={6} md={3} p={1}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbnail1.jpeg")}
              style={styles.thumbnail}
              loading="lazy"
            />
          </Grid>
          <Grid justifyContent="center" xs={12} sm={6} md={3} p={1}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbnail2.jpeg")}
              style={styles.thumbnail}
              loading="lazy"
            />
          </Grid>
          <Grid justifyContent="center" xs={12} sm={6} md={3} p={1}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbnail3.jpeg")}
              style={styles.thumbnail}
              loading="lazy"
            />
          </Grid>
          <Grid justifyContent="center" xs={12} sm={6} md={3} p={1}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbnail4.jpeg")}
              style={styles.thumbnail}
              loading="lazy"
            />
          </Grid>
          <Grid justifyContent="center" xs={12} sm={6} md={3} p={1}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbnail5.jpeg")}
              style={styles.thumbnail}
              loading="lazy"
            />
          </Grid>
          <Grid justifyContent="center" xs={12} sm={6} md={3} p={1}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbnail6.jpeg")}
              style={styles.thumbnail}
              loading="lazy"
            />
          </Grid>
          <Grid justifyContent="center" xs={12} sm={6} md={3} p={1}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbnail7.jpeg")}
              style={styles.thumbnail}
              loading="lazy"
            />
          </Grid>
          <Grid justifyContent="center" xs={12} sm={6} md={3} p={1}>
            <img
              alt="logoBn2much"
              src={require("../Constant/img/thumbnail8.jpeg")}
              style={styles.thumbnail}
              loading="lazy"
            />
          </Grid>
        </Grid>
        <Grid container direction="column" style={styles.body1} mb={3}>
          <Grid container justifyContent="center" flexDirection={"column"}>
            <Typography variant="h5" gutterBottom style={styles.headingLogo}>
              Bn2much รับซื้อ-ขาย-แลกเปลี่ยน กระเป๋าแบรนด์เนม
            </Typography>
            <Typography variant="h5" gutterBottom style={styles.headingLogo}>
              กระเป๋าแบรนด์เนม มือสอง ของแท้
            </Typography>
            <Typography variant="h5" gutterBottom style={styles.headingLogo}>
              เชียงใหม่ รับซื้อถึงที่
            </Typography>
          </Grid>
          <Grid container justifyContent="center"></Grid>
          <Grid container justifyContent="center">
            <Link href={LINGOPENCHAT}>
              <img
                alt="logoBn2much"
                src={require("../Constant/img/thumbNailLineSquare.png")}
                style={styles.bodyImage}
                loading="lazy"
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>

      {/* footer */}
      <Grid container spacing={2} style={styles.subContainer}>
        <Grid xs={12} md={8} style={styles.footer}>
          © {currentYear}, Designed by Bn2much
        </Grid>
      </Grid>
    </Box>
  );
}
